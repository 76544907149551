import { NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { AngularRemixIconComponent } from 'angular-remix-icon';
import { NgxGlideComponent } from 'ngx-glide';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { jsonCopy, openUrl } from '../misc/utilities';
import { CarouselItem, extensionToMediaTypeMap, MediaType } from '../model/carousel.model';
import { SanitizeMessagePipe } from '../pipes/sanitize-message.pipe';
import { DisplayService } from '../services/display.service';
import { LoggerService } from '../services/logger/logger.service';

/** Delay at which window resize event will be taken into accout */
const DEBOUNCE_TIME = 250;

/**
 * Previously coded inline into the message component and refactored to become a "standalone" component.
 */
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  standalone: true,
  imports: [
    NgIf,
    FlexModule,
    AngularRemixIconComponent,
    NgStyle,
    ExtendedModule,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    NgClass,
    SanitizeMessagePipe,
    NgxGlideComponent,
  ],
})
export class CarouselComponent implements OnDestroy {
  /** The text message accompanying the Carousel where there is one. */
  @Input() message: string;

  private vCarouselItems: CarouselItem[];
  @Input()
  public get carouselItems(): CarouselItem[] {
    return this.vCarouselItems;
  }

  public set carouselItems(value: CarouselItem[]) {
    this.vCarouselItems = jsonCopy(value);
    if (this.carouselItems?.length) {
      this.carouselIndex = 0;
      this.setMediaTypes();
    }
  }

  @Input() isVisitor: boolean;
  @Input() isDelivered: boolean;

  @ViewChild('ngxGlide') ngxGlide!: NgxGlideComponent;

  private subs: Subscription[] = [];
  private resizeSubject: Subject<Event> = new Subject();
  public hoverInd = -1;
  public carouselItemSize: { width: number; height: number } = { width: null, height: null };
  public carouselIndex: number = -1;
  public viewOpenUrl = openUrl;
  private vCarouselMediaContainer: ElementRef<HTMLDivElement> = null;

  @ViewChild('mediaContainer', { static: false })
  public set carouselMediaContainer(container: ElementRef<HTMLDivElement>) {
    if (container?.nativeElement) {
      setTimeout(() => (this.vCarouselMediaContainer = container));
    }
  }
  public get carouselMediaContainer(): ElementRef<HTMLDivElement> {
    return this.vCarouselMediaContainer;
  }
  get currentCarouselItem(): CarouselItem {
    return this.carouselItems[this.carouselIndex];
  }
  constructor(
    private logger: LoggerService,
    public displayService: DisplayService,
  ) {
    this.subs.push(
      this.resizeSubject.pipe(debounceTime(DEBOUNCE_TIME)).subscribe(() => {
        this.carouselOnResize();
      }),
    );
  }
  ngOnDestroy(): void {
    this.subs?.forEach?.((sub) => sub?.unsubscribe?.());
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeSubject.next();
    this.recreate();
  }

  recreate(): void {
    if (this.ngxGlide) {
      this.ngxGlide.recreate();
    }
  }

  setMediaTypes() {
    for (const carouselItem of this.carouselItems) {
      const mediaType = this.inferMediaType(carouselItem.mediaUrl);
      carouselItem.mediaType = mediaType ?? 'image';
    }
  }

  private carouselOnResize() {
    this.recreate();
  }

  private inferMediaType(url: string): MediaType | undefined {
    let path = '';
    try {
      const urlInstance = new URL(url);
      path = urlInstance.pathname;
    } catch (error) {
      this.logger.info('Carousel', 'Trying to parse an invalid url:', url);
    }
    const extension = path.split('.').pop()?.toLowerCase().trim();
    if (extensionToMediaTypeMap[extension]) {
      return extensionToMediaTypeMap[extension];
    }
    return undefined;
  }

  onRan(move: { direction: string; steps: number }): void {
    if (move.direction === '>') {
      this.carouselIndex = this.getPosition(this.carouselIndex + 1, this.carouselItems.length);
    }
    if (move.direction === '<') {
      this.carouselIndex = this.getPosition(this.carouselIndex - 1, this.carouselItems.length);
    }

    this.logger.debug(this.ngxGlide);
    this.logger.debug({ event: 'ran', data: move });
  }

  /**
   * Calculates the position of an index within a length, supporting positive and negative indices.
   * @param index - The input index, can be positive or negative.
   * @param length - The length of the range, must be a positive number.
   * @returns The valid position of the index within the range [0, length - 1] or always 0 if length is 0
   */
  getPosition(index: number, length: number): number {
    if (length <= 0) {
      return 0;
    }
    const position = ((index % length) + length) % length;
    return position;
  }
}
