<div *ngIf="message?.length" class="preamble" [innerHTML]="message | sanitizeMessage"></div>

<ng-template #arrowLeftTemplate>
  <div class="arrow-ctn">
    <rmx-icon
      name="arrow-left-s-line"
      [ngStyle]="{color: displayService.chatStyle.headerStyle.background}"
    ></rmx-icon>
</div>
</ng-template>

<ng-template #arrowRightTemplate>
  <div class="arrow-ctn">
    <rmx-icon
      name="arrow-right-s-line"
      [ngStyle]="{color: displayService.chatStyle.headerStyle.background}"
    ></rmx-icon>
  </div>
</ng-template>

<ngx-glide
  type="carousel"
  [showBullets]="false"
  [startAt]="0"
  [perView]="1"
  [listenToEvents]="true"
  (ran)="onRan($event)"
  [arrowLeftTemplate]="arrowLeftTemplate"
  [arrowRightTemplate]="arrowRightTemplate"
  #ngxGlide
>
  <ng-container *ngFor="let carouselItem of carouselItems; let carouselItemIndex = index;">
    <ng-container [ngSwitch]="carouselItem.mediaType">

      <!-- Image -->
      <img
        *ngSwitchCase="'image'"
        [src]="carouselItem.mediaUrl"
        class="carousel-item media image"
        [ngClass]="{clickable: carouselItem.clickable?.length > 0 && carouselItem.clickable.includes('mediaUrl')}"
        (click)="carouselItem.clickable.includes('mediaUrl') ? viewOpenUrl(carouselItem.url) : null"
      />

      <!-- Video -->
      <video
        controls
        autoplay
        muted
        loop
        fxFlex="grow"
        *ngSwitchCase="'video'" class="media video"
        [ngClass]="{
          clickable:
            carouselItem.clickable?.length > 0 &&
            carouselItem.clickable.includes('mediaUrl')
        }"
        [ngStyle]="{ 'z-index': carouselItems.length - carouselItemIndex }"
        [src]="carouselItem.mediaUrl"
        (click)="carouselItem.clickable.includes('mediaUrl') ? viewOpenUrl(carouselItem.url) : null"
      >
      </video>

    </ng-container>
  </ng-container>
</ngx-glide>

<div
  *ngIf="currentCarouselItem.description"
  class="description"
  [ngClass]="{
    'no-top-border-radius': currentCarouselItem.mediaUrl && currentCarouselItem.mediaUrl !== '',
    clickable: currentCarouselItem.clickable?.length > 0 && currentCarouselItem.clickable.includes('description')
  }"
  [ngStyle]="displayService.getMessageStyle(isVisitor, isDelivered)"
  (click)="
  currentCarouselItem.clickable.includes('description')
      ? viewOpenUrl(currentCarouselItem.url)
      : null
  "
  [innerHTML]="currentCarouselItem.description | sanitizeMessage">
</div>
