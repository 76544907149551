<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" [ngClass]="{
    hideMessage: message.content === '',
    messageWrapper: true,
    visitorMessage: isVisitor,
    wide: ['buttons', 'quick_replies', 'carousel', 'order_tracking'].includes(message.type) || htmlCarousel?.items?.length,
    selfCareEnabled: selfCareEnabled,
    contactFormModeEnabled: chatMode === 'form'
  }" [ngStyle]="displayService.chatStyle.bgStyle"
  [style.marginBottom.px]="{ '12': message.schemaSatisfaction || message.isLastStep }" [style.width]="
    message.type === 'image' || message.type === 'VIDEO'
      ? '85%'
      : message.isButtonReply
      ? 'auto'
      : null
  ">
  <div fxFlex="nogrow" fxFlexAlign="end" class="avatar" *ngIf="!isVisitor && chatMode !== 'form'"
    [ngClass]="{ hideAvatar: !displayAvatar }" [ngStyle]="{ 'background-image': avatarUrl }"></div>

  <div fxFlex="grow" fxLayout="column" [fxLayoutAlign]="isVisitor ? ' end' : ' start'" fxLayoutGap="4px"
    [class]="'messageContent' + ' ' + message.type"
    [fxFlexOrder]="isVisitor ? 2 : 1"
    [ngSwitch]="message.type"
    [ngClass]="{ isButtonReplyContainer: message.isButtonReply }"
    [ngStyle]="getMessageContentStyle(message.type)"
  >
    <span class="authorName" *ngIf="displayName && !isVisitor">{{ fullName }}</span>

    <ng-container *ngIf="!isHtmlCarousel">

      <!-- ============================================== TEXT =================================================== -->
      <div *ngSwitchCase="'text'"
        [style.color]="isVisitor && message.isButtonReply ? getButtonsItemStyle(-999).color : null"
        [ngClass]="{
          precededBySameAuthor: precededBySameAuthor,
          followedBySameAuthor: followedBySameAuthor,
          isButtonReply: chatMode === 'form' && message.isButtonReply,
          focused: focused,
          message: chatMode !== 'form'
        }"
        [ngStyle]="chatMode !== 'form' ? displayService.getMessageStyle(isVisitor, message.delivered) : null"
        [innerHTML]="messageContentAsAny | sanitizeMessage : message.author?.type">
      </div>

      <!-- ========================================= STREAMED TEXT =============================================== -->
      <div *ngSwitchCase="'streamed_text'"
        class="streamed-text"
        [style.color]="isVisitor && message.isButtonReply ? getButtonsItemStyle(-999).color : null"
        [ngClass]="{
          'precededBySameAuthor': precededBySameAuthor,
          'followedBySameAuthor': followedBySameAuthor,
          'isButtonReply': chatMode === 'form' && message.isButtonReply,
          'focused': focused,
          'message': chatMode !== 'form',
        }"
        [ngStyle]="
          chatMode !== 'form' ? displayService.getMessageStyle(isVisitor, message.delivered) : null
        "
        [innerHTML]="textStreamMessage$ | async | sanitizeMessage : message.author?.type"
      >
      </div>
      <!-- ========================================= TEXT_PAYLOAD =============================================== -->
      <div *ngSwitchCase="'text_payload'"
        [style.color]="isVisitor && message.isButtonReply ? getButtonsItemStyle(-999).color : null" [ngClass]="{
          precededBySameAuthor: precededBySameAuthor,
          followedBySameAuthor: followedBySameAuthor,
          isButtonReply: chatMode === 'form' && message.isButtonReply,
          focused: focused,
          message: chatMode !== 'form'
        }" [ngStyle]="
          chatMode !== 'form' ? displayService.getMessageStyle(isVisitor, message.delivered) : null
        "
        [innerHTML]="messageContentAsAny.text | sanitizeMessage : message.author?.type">
      </div>

    </ng-container>

    <ng-container *ngIf="message.type === 'image' || message.type === 'VIDEO'">
      <ngx-skeleton-loader count="1" appearance="circle" *ngIf="!message.mediaLoaded" [theme]="{
          width: '100%',
          height: '100%',
          'border-radius': '10px',
          'aspect-ratio': '16/9'
        }" [style.width]="'100%'"></ngx-skeleton-loader>

    <!-- ============================================ IMAGE =================================================== -->
      <div *ngSwitchCase="'image'">
        <a *ngIf="openImageOnClick" [href]="imageMessageContent" target="_blank"><img class="message image-message"
            width="100%" [src]="imageMessageContent"
            [ngStyle]="displayService.getMessageStyle(isVisitor, message.delivered)"
            [style.display]="message?.mediaLoaded ? 'initial' : 'none'"
            (load)="onImageLoad(); message.mediaLoaded = true" /></a>
      </div>
      <img
        class="message image-message"
        width="100%"
        *ngIf="!openImageOnClick"
        [src]="imageMessageContent"
        [ngStyle]="displayService.getMessageStyle(isVisitor, message.delivered)"
        [style.display]="message?.mediaLoaded ? 'initial' : 'none'"
        (load)="onImageLoad(); message.mediaLoaded = true"
      />
    <!-- ============================================ VIDEO =================================================== -->
      <video class="message-video" autoplay controls width="100%" *ngSwitchCase="'video'" [muted]="'muted'"
        [style.display]="message?.mediaLoaded ? 'initial' : 'none'" (canplay)="message.mediaLoaded = true">
        <source type="video/mp4" [src]="message.content" />
        Sorry, your browser doesn't support embedded videos..
      </video>
    </ng-container>

    <!-- ============================================ BUTTONS =================================================== -->
    <ng-container *ngSwitchCase="'buttons'">
      <div fxLayout="row wrap" style="gap: 6px" class="buttons"
        *ngIf="(chatMode !== 'form' || !isLongButton) && !isVeryLongButton"
        [style.width.%]="chatMode === 'form' && !isVisitor ? '100' : null" [ngStyle]="getButtonsStyle()" [ngClass]="{
          'buttons-self-care': selfCareEnabled,
          'welcome-buttons-self-care': selfCareEnabled && widget.expandHeader,
          'buttons-contact-form': chatMode === 'form'
        }">
        <div style="flex-grow: 1; min-width: 25%" class="buttons-item"
          *ngFor="let item of message.content; let i = index" [ngStyle]="getButtonsItemStyle(i)"
          (click)="processButtonMessage(item)" (mouseover)="hoverInd = i" (mouseleave)="hoverInd = -1">
          <a *ngIf="
              item.type === 'link' &&
              ((item.subtype && item.subtype === 'text-link') || !item.subtype)
            " [href]="item.content" target="_blank" style="text-decoration: none; color: inherit" [attr.datatitle]="item.text">
            {{ item.text }}</a>
          <a *ngIf="item.type === 'link' && item.subtype === 'image-link'" [href]="item.content" target="_blank"
            style="text-decoration: none; color: inherit">
            <img class="message" [src]="item.text" width="100%"
              [ngStyle]="displayService.getMessageStyle(isVisitor, message.delivered)" (load)="onImageLoad()" /></a>
          <span *ngIf="item.type === 'reply'" [attr.datatitle]="item.text"> {{ item.text }} </span>
        </div>
      </div>

      <div *ngIf="chatMode !== 'form' && isVeryLongButton" class="select-buttons">
        <select (change)="setSelectButtonMessageItem($event)" class="select2">
          <option *ngFor="let item of message.content; let i = index" class="select-button-item" [value]="i">
            {{ item.text }}
          </option>
        </select>
        <button [disabled]="!validButtonMessageItem" [ngStyle]="getButtonsItemStyle(-999)"
          (click)="selectButtonMessage()" (mouseenter)="hoverInd = -999" (mouseleave)="hoverInd = -1">
          {{ 'NEXT' | translate }}
        </button>
      </div>

      <div *ngIf="chatMode === 'form' && isLongButton" class="select-buttons">
        <select (change)="setSelectButtonMessageItem($event)" class="select1">
          <option *ngFor="let item of message.content; let i = index" class="select-button-item" [value]="i">
            {{ item.text }}
          </option>
        </select>
        <button (click)="selectButtonMessage()" [disabled]="!validButtonMessageItem" [ngStyle]="{
            backgroundColor: displayService.chatStyle.sendButtonColor,
            borderColor: displayService.chatStyle.sendButtonColor,
            color: displayService.chatStyle.headerStyle.textAndLogo.color
          }">
          {{ 'NEXT' | translate }}
        </button>
      </div>
    </ng-container>

    <!-- ========================================= QUICK_REPLIES ================================================ -->
    <div *ngSwitchCase="'quick_replies'" class="quick-replies">
      <div *ngFor="let item of message.content" class="quick-replies-item" (click)="sendTextMessage(item)">
        {{ item }}
      </div>
    </div>

    <!-- =========================================== ATTACHMENT ================================================= -->
    <div *ngSwitchCase="'attachment'" class="attachment" [ngClass]="message.type">
      <a [href]="messageContentAsAny.url" target="_blank">
        <img *ngIf="!isVideoMessageContent(message.content)" [src]="attachmentSrc" width="80" height="80" />
        <rmx-icon class="video-icon" [ngStyle]="{ color: displayService.chatStyle.sendButtonColor }"
          *ngIf="isVideoMessageContent(message.content)" [name]="attachmentSrc"></rmx-icon>
      </a>
    </div>

    <!-- ============================================= WEBVIEW ================================================== -->
    <div *ngSwitchCase="'webview'" class="webview" (click)="showWebview(message.content)">
      <div class="message">
        <rmx-icon name="window-2-line"></rmx-icon>
        <span>{{ message.content }}</span>
      </div>
    </div>


    <!-- ============================================= CAROUSEL ================================================= -->
    <div *ngSwitchCase="'carousel'" fxFlex="nogrow" fxLayout="column" fxLayoutAlign="center center" class="carousel">
        <app-carousel [carouselItems]="message.content" [isVisitor]="isVisitor" [isDelivered]="message.delivered"></app-carousel>
    </div>


    <!-- =========================================== HTML_CAROUSEL ================================================ -->
    <ng-container *ngIf="isHtmlCarousel">
      <div fxFlex="nogrow" fxLayout="column" fxLayoutAlign="center center" class="carousel">
        <app-carousel [message]="htmlCarousel?.message" [carouselItems]="htmlCarousel?.items" [isVisitor]="isVisitor" [isDelivered]="message.delivered"></app-carousel>
      </div>
    </ng-container>

    <!-- =========================================== ORDER_TRACKING ============================================= -->
    <div fxFlex="grow" fxLayout="column" class="order-tracking" *ngSwitchCase="'order_tracking'">
      <div fxFlex="grow" fxLayout="row wrap" fxLayoutGap="8px" class="tracking-and-details">
        <!-- Left block -->
        <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="center start" class="tracking">
          <h2 fxFlex="nogrow" class="order-status-title" *ngIf="
              orderTrackingStatus.includes(messageContentAsAny.orderStatus) ||
                messageContentAsAny.customStatusTitle;
              else nonTranslatableOrderStatusTitle
            ">
            {{
            messageContentAsAny.customStatusTitle
            ? messageContentAsAny.customStatusTitle
            : ('MESSAGE.ORDER_TRACKING.STATUS.' + messageContentAsAny.orderStatus + '.TITLE'
            | translate)
            }}
          </h2>
          <ng-template #nonTranslatableOrderStatusTitle>
            <h2 fxFlex="nogrow" class="order-status-title">
              {{
              messageContentAsAny.customStatusTitle
              ? messageContentAsAny.customStatusTitle
              : messageContentAsAny.orderStatus
              }}
            </h2>
          </ng-template>
          <p fxFlex="nogrow" class="order-status-label" *ngIf="
              orderTrackingStatus.includes(messageContentAsAny.orderStatus) ||
              messageContentAsAny.customStatusLabel
            ">
            {{
            messageContentAsAny.customStatusLabel
            ? messageContentAsAny.customStatusLabel
            : ('MESSAGE.ORDER_TRACKING.STATUS.' + messageContentAsAny.orderStatus + '.LABEL'
            | translate)
            }}
          </p>
        </div>

        <!-- Right block -->
        <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="center center" class="details">
          <ng-container [ngSwitch]="messageContentAsAny.orderStatus">
            <img class="status-illustration" [src]="
                messageContentAsAny.customStatusImageUrl
                  ? messageContentAsAny.customStatusImageUrl
                  : ('../../assets/img/blocks/order-tracking/' +
                      messageContentAsAny.orderStatus +
                      '.svg' | lowercase)
              " [alt]="'Order status: ' + messageContentAsAny.orderStatus.toLowerCase()" />

            <!-- Available to withdraw -->
            <ng-container *ngSwitchCase="orderTrackingStatus[2]">
              {{
              'MESSAGE.ORDER_TRACKING.STATUS.' +
              messageContentAsAny.orderStatus +
              '.DETAILS.DELIVERY_DATE' | translate
              }}
              <p fxFlex="nogrow" class="delivery-date">{{ messageContentAsAny.deliveryDate }}</p>
            </ng-container>

            <!-- Collected -->
            <ng-container *ngSwitchCase="orderTrackingStatus[3]">
              <span fxFlex="nogrow" class="delivery-date">
                {{
                'MESSAGE.ORDER_TRACKING.STATUS.' +
                messageContentAsAny.orderStatus +
                '.DETAILS.DELIVERY_DATE' | translate
                }}
                {{ messageContentAsAny.deliveryDate }}
              </span>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <ng-container *ngIf="messageContentAsAny.estimatedDeliveryDate">
                <span fxFlex="nogrow" class="estimated-delivery-date">
                  {{
                  'MESSAGE.ORDER_TRACKING.STATUS.' +
                  messageContentAsAny.orderStatus +
                  '.DETAILS.ESTIMATED_DELIVERY_DATE' | translate
                  }}
                  {{ messageContentAsAny.estimatedDeliveryDate }}
                </span>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <button fxFlex="nogrow" type="button" class="status-link-button" *ngIf="messageContentAsAny.orderStatusLink"
        [ngStyle]="getButtonsItemStyle(-9999)" (mouseover)="hoverInd = -9999" (mouseleave)="hoverInd = -1"
        (click)="viewOpenUrl(messageContentAsAny.orderStatusLink)">
        {{
        messageContentAsAny.trackingButtonLabel
        ? messageContentAsAny.trackingButtonLabel
        : ('MESSAGE.ORDER_TRACKING.STATUS_LINK_BUTTON' | translate)
        }}
      </button>
    </div>

    <span class="sent-status" *ngIf="isVisitor && isLastMessage && message.delivered && chatMode !== 'form'">
      {{ 'DELIVERED' | translate }}
    </span>
    <span *ngIf="isVisitor && !message.delivered && chatMode !== 'form'" class="sent-status">
      {{ 'DELIVERING' | translate }}
    </span>

    <!-- Schema satisfaction -->
    <div fxLayout="row" fxLayoutGap="4px" class="schema-satisfaction"
      *ngIf="enableSchemaSatisfaction && message.showSchemaSatisfaction && !isVisitor"
      [ngClass]="{ set: !!message.schemaSatisfaction, unset: !message.schemaSatisfaction }">
      <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center center" class="icon-container"
        *ngIf="!message.schemaSatisfaction || message.schemaSatisfaction === 'unsatisfied'"
        (click)="setSchemaSatisfaction(!message.schemaSatisfaction ? 'unsatisfied' : null)">
        <rmx-icon fxFlex="nogrow" name="thumb-down-line" *ngIf="!message.schemaSatisfaction"></rmx-icon>
        <rmx-icon fxFlex="nogrow" name="thumb-down-fill"
          *ngIf="message.schemaSatisfaction === 'unsatisfied'"></rmx-icon>
      </div>

      <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center center" class="icon-container"
        *ngIf="!message.schemaSatisfaction || message.schemaSatisfaction === 'satisfied'"
        (click)="setSchemaSatisfaction(!message.schemaSatisfaction ? 'satisfied' : null)">
        <rmx-icon fxFlex="nogrow" name="thumb-up-line" *ngIf="!message.schemaSatisfaction"></rmx-icon>
        <rmx-icon fxFlex="nogrow" name="thumb-up-fill" *ngIf="message.schemaSatisfaction === 'satisfied'"></rmx-icon>
      </div>
    </div>
  </div>

  <span fxFlex="nogrow" class="message-time" *ngIf="chatMode !== 'form' && message.type !== 'carousel'"
    [fxFlexOrder]="isVisitor ? 1 : 2" [ngStyle]="{ transform: displayName && !isVisitor ? 'translateY(9px)' : null }">
    {{ formattedTime }}
  </span>
</div>
