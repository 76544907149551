import { TranslationArray } from './Session';

export enum ActorType {
  LEAD = 'lead',
  USER = 'visitor',
  AGENT = 'agent',
  SYSTEM = 'system',
  BOT = 'bot',
  THIRD_PARTY = 'third_party',
}

export class User {
  public translatedNames?: TranslationArray<{ firstName: string; lastName: string }>;

  public constructor(
    public email: string,
    public firstName: string,
    public lastName: string,
    public type: ActorType,
    public id: string | null,
    public avatar: string,
    public customData: { key: string; value: string }[],
  ) {}

  public static getFullName(
    user?: User,
    currentLanguage?: string,
    actorAliases?: { [actorId: string]: { avatarUrl: string; nickName: string } } | null,
  ): string {
    const defaultName = 'Agent';
    if (!user) return defaultName;

    // Check for translated names
    if (currentLanguage && user.translatedNames?.length) {
      const translation = user.translatedNames.find((tN) => tN.language === currentLanguage)?.content;
      if (translation?.firstName || translation?.lastName) {
        return [translation.firstName, translation.lastName].filter(Boolean).join(' ');
      }
    }

    // Check for aliases
    if (actorAliases?.[user.id]?.nickName) {
      return actorAliases[user.id].nickName;
    }

    // Fallback to first name and last name
    const fallbackName = [user.firstName, user.lastName].filter(Boolean).join(' ');
    return fallbackName || defaultName;
  }
}
